import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProjectsContext } from "../data/contexts/ProjectsContext";
import { dateConvertToString } from "../data/dataConvertors";
import { UserLightFragment } from "../data/generated/graphql";
import GdDataGrid, { GdColumn, GdDataExtractor } from "../utils/GdDataGrid";
import { checkIfProjectIsEmpty, checkIfProjectIsTest } from "../data/lists/ProjectStatuses";
import Pager from "../common/Pager";

interface UsersSummaryProps {
  users: UserLightFragment[];
}

const UsersSummary: FC<UsersSummaryProps> = ({ users }) => {
  const { projects } = useContext(ProjectsContext);
  const { t } = useTranslation("user");
  const [currentPage, setCurrentPage] = useState(0);

  const usersOnPage = users.slice(10 * currentPage, 10 * (currentPage + 1));
  const nbPages = users.length > 10 ? Math.ceil(users.length / 10) : 1;

  useEffect(() => {
    setCurrentPage(0);
  }, [projects]);

  const createCol = (key: string, dataExtractor?: GdDataExtractor): GdColumn => ({
    key,
    label: t(`usersCols.${key}`),
    dataExtractor,
  });

  const userColumns: GdColumn[] = [
    createCol("name"),
    createCol("email"),
    createCol("role"),
    createCol("joined", (d) => dateConvertToString((d.joined as string) || "")),
    createCol("nbProjects", (d) =>
      projects
        .filter((p) => p.userId === d.id && p.active && !checkIfProjectIsEmpty(p) && !checkIfProjectIsTest(p))
        .length.toString(),
    ),
    createCol("status", (d) => t(`userStatus.${d.active}`)),
  ];

  return (
    <div
      style={{
        height: "900px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
      }}>
      <GdDataGrid columns={userColumns} data={usersOnPage} idExtractor={(u) => u.id as string} link="/users/" hover />
      <Pager
        page={currentPage + 1}
        nbPages={nbPages}
        onNext={() => setCurrentPage(currentPage + 1)}
        onPrevious={() => setCurrentPage(currentPage - 1)}
        onFirst={() => setCurrentPage(0)}
        onLast={() => setCurrentPage(nbPages - 1)}
      />
    </div>
  );
};

export default UsersSummary;
