import { FC } from "react";
import { useTranslation } from "react-i18next";
import { IconButton, styled, Tooltip, Typography } from "@mui/material";
import { FirstPage, LastPage, NavigateBefore, NavigateNext } from "@mui/icons-material";

const PagerRoot = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  alignItems: "center",
});

const Pager: FC<{
  page: number;
  nbPages: number;
  onNext: () => void;
  onPrevious: () => void;
  onFirst: () => void;
  onLast: () => void;
}> = ({ page, nbPages, onNext, onPrevious, onFirst, onLast }) => {
  const { t } = useTranslation("project");
  return (
    <PagerRoot>
      <Tooltip title={t("firstPage")} arrow>
        <IconButton onClick={onFirst} disabled={page === 1}>
          <FirstPage />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("previousPage")} arrow>
        <IconButton onClick={onPrevious} disabled={page === 1}>
          <NavigateBefore />
        </IconButton>
      </Tooltip>
      <Typography style={{ marginLeft: 32, marginRight: 32 }}>{t("pageOf", { replace: { page, nbPages } })}</Typography>
      <Tooltip title={t("nextPage")} arrow>
        <IconButton onClick={onNext} disabled={page === nbPages}>
          <NavigateNext />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("lastPage")} arrow>
        <IconButton onClick={onLast} disabled={page === nbPages}>
          <LastPage />
        </IconButton>
      </Tooltip>
    </PagerRoot>
  );
};

export default Pager;
